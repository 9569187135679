// npm
import React, { memo } from 'react'
import { graphql } from 'gatsby'
// src
import BasicPageLayout from 'templates/layouts/BasicPageLayout'
import Markdown from '@atoms/text/Markdown'

interface Props {
  data: Queries.BasicTextPageQuery
}

const BasicTextPage = ({ data }: Props) => {
  const d = data.datoCmsBasicTextPage

  return (
    <BasicPageLayout
      keyId={d.originalId}
      smallHeading={d.smallHeading}
      mainHeading={d.mainHeadingNode.childMarkdownRemark.html}
      textAlign="left"
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <Markdown html={d.textNode.childMarkdownRemark.html} />
    </BasicPageLayout>
  )
}

export default memo(BasicTextPage)

export const pageQuery = graphql`
  query BasicTextPage($originalId: String!) {
    datoCmsBasicTextPage(originalId: { eq: $originalId }) {
      originalId
      smallHeading
      textNode {
        childMarkdownRemark {
          html
        }
      }
      mainHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
